import { Injectable } from '@angular/core';

import {
  MfaSetupRequest,
  MfaSetupResponse,
  MfaEnableRequest,
  MfaResponse,
  MfaDisableRequest,
  MfaUpdateBackupEmailRequest,
  MfaEnforceRequest,
  MfaLoginRequest,
  MfaBackupCodeResponse,
  MfaLoginWithOTPRequest,
  MfaVerifyBackupEmailRequest,
  MfaBackupCodeRequest,
} from './mfa.model';

import { Observable } from 'rxjs';
import { ApiGateway } from '../../api/ApiGateway.service';
import { UnsafeAction as Action } from '../interfaces';

@Injectable()
export class MfaApi {
  private endpoint = 'multifactor/';

  constructor(private gateway: ApiGateway) {}

  setup(mfa: MfaSetupRequest): Observable<MfaSetupResponse> {
    return this.gateway.post(this.endpoint + 'setup', mfa);
  }

  enable(mfa: MfaEnableRequest): Observable<MfaResponse> {
    return this.gateway.post(this.endpoint + 'enable', mfa);
  }

  disable(mfa: MfaDisableRequest): Observable<MfaResponse> {
    return this.gateway.post(this.endpoint + 'disable', mfa);
  }

  disableForUser(userId: string): Observable<MfaResponse> {
    return this.gateway.get(this.endpoint + 'disable/' + userId);
  }

  login(mfa: MfaLoginRequest, dispatchStart?: Action): Observable<any> {
    return this.gateway.post(this.endpoint + 'challenge', mfa, undefined, dispatchStart);
  }

  loginWithOTP(mfa: MfaLoginWithOTPRequest, dispatchStart?: Action): Observable<any> {
    return this.gateway.post(this.endpoint + 'email', mfa, undefined, dispatchStart);
  }

  sendEmailWithOTPCode(): Observable<any> {
    return this.gateway.get(this.endpoint + 'email');
  }

  getBackupCode(mfa: MfaBackupCodeRequest): Observable<MfaBackupCodeResponse> {
    return this.gateway.post(this.endpoint + 'backup', mfa);
  }

  verifyBackupCode(mfa: MfaBackupCodeResponse): Observable<any> {
    return this.gateway.post(this.endpoint + 'verify_backup_code', mfa);
  }

  updateBackupEmail(mfa: MfaUpdateBackupEmailRequest): Observable<any> {
    return this.gateway.post(this.endpoint + 'email/update', mfa);
  }

  verifyBackupEmail(mfa: MfaVerifyBackupEmailRequest): Observable<any> {
    return this.gateway.post(this.endpoint + 'email/verify', mfa);
  }

  updatePromoteState(): Observable<any> {
    return this.gateway.post(this.endpoint + 'promote', null);
  }

  refreshBackupCode() {
    return this.gateway.get(this.endpoint + 'refresh/backup_code');
  }
}
