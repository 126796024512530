import { UnsafeAction as Action } from '../interfaces';
import { Message, MessageActionData } from './message.model';

const ACTION_PREFIX = '[Message]';
export const messageActionType = {
  ADD_MESSAGE: `${ACTION_PREFIX} Add`,
  REMOVE_MESSAGE: `${ACTION_PREFIX} Remove`,
};

var id: number = 0;

function createMessage(type: string, message: string, dismissAfter: number, action?: MessageActionData): Message {
  id++;
  return <Message>{
    id,
    type,
    message,
    dismissAfter,
    action,
  };
}

export class MessageAction {
  static success(message: string, dismissAfter: number = 3000, action?: MessageActionData): Action {
    return {
      type: messageActionType.ADD_MESSAGE,
      payload: createMessage('success', message, dismissAfter, action),
    };
  }

  static error(message: string, dismissAfter: number = 3000, action?: MessageActionData): Action {
    return {
      type: messageActionType.ADD_MESSAGE,
      payload: createMessage('error', message, dismissAfter, action),
    };
  }

  static notification(message: string, dismissAfter: number = 3000, action?: MessageActionData): Action {
    return {
      type: messageActionType.ADD_MESSAGE,
      payload: createMessage('warn', message, dismissAfter, action),
    };
  }

  static remove(messageId: number): Action {
    return {
      type: messageActionType.REMOVE_MESSAGE,
      payload: messageId,
    };
  }
}
