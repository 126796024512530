import { UnsafeAction as Action } from '../interfaces';

import {
  MfaSetupRequest,
  MfaSetupResponse,
  MfaResponse,
  MfaEnableRequest,
  MfaDisableRequest,
  MfaUpdateBackupEmailRequest,
  MfaLoginRequest,
  MfaEnforceRequest,
  MfaBackupCodeResponse,
  MfaLoginWithOTPRequest,
  MfaVerifyBackupEmailRequest,
  MfaBackupCodeRequest,
} from './mfa.model';

const ACTION_PREFIX = '[MFA]';
export const mfaActionType = {
  SETUP: `${ACTION_PREFIX} Setup`,
  SETUP_SUCCESS: `${ACTION_PREFIX} Setup Success`,
  SETUP_FAILED: `${ACTION_PREFIX} Setup Failed`,

  ENABLE: `${ACTION_PREFIX} Enable`,
  ENABLE_SUCCESS: `${ACTION_PREFIX} Enable Success`,
  ENABLE_FAILED: `${ACTION_PREFIX} Enable Failed`,

  DISABLE: `${ACTION_PREFIX} Disable`,
  DISABLE_SUCCESS: `${ACTION_PREFIX} Disable Success`,
  DISABLE_FAILED: `${ACTION_PREFIX} Disable Failed`,

  DISABLE_FOR_USER: `${ACTION_PREFIX} Disable For User`,
  DISABLE_FOR_USER_SUCCESS: `${ACTION_PREFIX} Disable For User Success`,
  DISABLE_FOR_USER_FAILED: `${ACTION_PREFIX} Disable For User Failed`,

  LOGIN: `${ACTION_PREFIX} Login`,
  LOGIN_SUCCESS: `${ACTION_PREFIX} Login Success`,
  LOGIN_FAILED: `${ACTION_PREFIX} Login Failed`,

  LOGIN_WITH_OTP: `${ACTION_PREFIX} Login with OTP`,
  LOGIN_WITH_OTP_SUCCESS: `${ACTION_PREFIX} Login with OTP Success`,
  LOGIN_WITH_OTP_FAILED: `${ACTION_PREFIX} Login with OTP Failed`,

  GET_BACKUP_CODE: `${ACTION_PREFIX} Get Backup Code`,
  GET_BACKUP_CODE_SUCCESS: `${ACTION_PREFIX} Get Backup Code Success`,
  GET_BACKUP_CODE_FAILED: `${ACTION_PREFIX} Get Backup Code Failed`,

  VERIFY_BACKUP_CODE: `${ACTION_PREFIX} Verify Backup Code`,
  VERIFY_BACKUP_CODE_SUCCESS: `${ACTION_PREFIX} Verify Backup Code Success`,
  VERIFY_BACKUP_CODE_FAILED: `${ACTION_PREFIX} Verify Backup Code Failed`,

  UPDATE_BACKUP_EMAIL: `${ACTION_PREFIX} Update Backup Email`,
  UPDATE_BACKUP_EMAIL_SUCCESS: `${ACTION_PREFIX} Update Backup Email Success`,
  UPDATE_BACKUP_EMAIL_FAILED: `${ACTION_PREFIX} Update Backup Email Failed`,

  VERIFY_BACKUP_EMAIL: `${ACTION_PREFIX} Verify Backup Email`,
  VERIFY_BACKUP_EMAIL_SUCCESS: `${ACTION_PREFIX} Verify Backup Email Success`,
  VERIFY_BACKUP_EMAIL_FAILED: `${ACTION_PREFIX} Verify Backup Email Failed`,

  SEND_EMAIL_WITH_OTP_CODE: `${ACTION_PREFIX} Send Email With OTP Code`,
  SEND_EMAIL_WITH_OTP_CODE_SUCCESS: `${ACTION_PREFIX} Send Email With OTP Code Success`,
  SEND_EMAIL_WITH_OTP_CODE_FAILED: `${ACTION_PREFIX} Send Email With OTP Code Failed`,

  ENFORCE: `${ACTION_PREFIX} Enforce`,
  ENFORCE_SUCCESS: `${ACTION_PREFIX} Enforce Success`,
  ENFORCE_FAILED: `${ACTION_PREFIX} Enforce Failed`,

  UPDATE_PROMOTE: `${ACTION_PREFIX} Promote`,
  UPDATE_PROMOTE_SUCCESS: `${ACTION_PREFIX} Promote Success`,
  UPDATE_PROMOTE_FAILED: `${ACTION_PREFIX} Promote Failed`,

  REFRESH_BACKUP_CODE_SUCCESS: `${ACTION_PREFIX} Refresh Backup Code Success`,
  REFRESH_BACKUP_CODE_FAILED: `${ACTION_PREFIX} Refresh Backup Code Failed`,
};

export class MfaAction {
  static setup(mfa: MfaSetupRequest): Action {
    return {
      type: mfaActionType.SETUP,
      payload: mfa,
    };
  }

  static setupSuccess(response: MfaSetupResponse): Action {
    return {
      type: mfaActionType.SETUP_SUCCESS,
      payload: response,
    };
  }

  static setupFailed(): Action {
    return {
      type: mfaActionType.SETUP_FAILED,
    };
  }

  static enable(mfa: MfaEnableRequest): Action {
    return {
      type: mfaActionType.ENABLE,
      payload: mfa,
    };
  }

  static enableSuccess(response: MfaResponse, payload): Action {
    return {
      type: mfaActionType.ENABLE_SUCCESS,
      payload: {
        response: response,
        payload: payload,
      },
    };
  }

  static enableFailed(): Action {
    return {
      type: mfaActionType.ENABLE_FAILED,
    };
  }

  static disable(mfa: MfaDisableRequest): Action {
    return {
      type: mfaActionType.DISABLE,
    };
  }

  static disableSuccess(response: MfaResponse): Action {
    return {
      type: mfaActionType.DISABLE_SUCCESS,
    };
  }

  static disableFailed(): Action {
    return {
      type: mfaActionType.DISABLE_FAILED,
    };
  }

  static disableForUser(userId: string): Action {
    return {
      type: mfaActionType.DISABLE_FOR_USER,
      payload: userId,
    };
  }

  static disableForUserSuccess(userId: string): Action {
    return {
      type: mfaActionType.DISABLE_FOR_USER_SUCCESS,
      payload: userId,
    };
  }

  static disableForUserFailed(): Action {
    return {
      type: mfaActionType.DISABLE_FOR_USER_FAILED,
    };
  }

  static login(mfa: MfaLoginRequest): Action {
    return {
      type: mfaActionType.LOGIN,
      payload: mfa,
    };
  }

  static loginSuccess(response: any): Action {
    return {
      type: mfaActionType.LOGIN_SUCCESS,
      payload: response,
    };
  }

  static loginFailed(): Action {
    return {
      type: mfaActionType.LOGIN_FAILED,
    };
  }

  static loginWithOTP(mfa: MfaLoginWithOTPRequest): Action {
    return {
      type: mfaActionType.LOGIN_WITH_OTP,
      payload: mfa,
    };
  }

  static loginWithOTPSuccess(response: any): Action {
    return {
      type: mfaActionType.LOGIN_WITH_OTP_SUCCESS,
      payload: response,
    };
  }

  static loginWithOTPFailed(): Action {
    return {
      type: mfaActionType.LOGIN_WITH_OTP_FAILED,
    };
  }

  static getBackupCode(mfa: MfaBackupCodeRequest): Action {
    return {
      type: mfaActionType.GET_BACKUP_CODE,
      payload: mfa,
    };
  }

  static getBackupCodeSuccess(response: MfaBackupCodeResponse): Action {
    return {
      type: mfaActionType.GET_BACKUP_CODE_SUCCESS,
      payload: response,
    };
  }

  static getBackupCodeFailed(): Action {
    return {
      type: mfaActionType.GET_BACKUP_CODE_FAILED,
    };
  }

  static verifyBackupCode(): Action {
    return {
      type: mfaActionType.VERIFY_BACKUP_CODE,
    };
  }

  static verifyBackupCodeSuccess(response: MfaBackupCodeResponse): Action {
    return {
      type: mfaActionType.VERIFY_BACKUP_CODE_SUCCESS,
      payload: response,
    };
  }

  static verifyBackupCodeFailed(): Action {
    return {
      type: mfaActionType.VERIFY_BACKUP_CODE_FAILED,
    };
  }

  static updateBackupEmail(mfa: MfaUpdateBackupEmailRequest): Action {
    return {
      type: mfaActionType.UPDATE_BACKUP_EMAIL,
      payload: mfa,
    };
  }

  static updateBackupEmailSuccess(response: any): Action {
    return {
      type: mfaActionType.UPDATE_BACKUP_EMAIL_SUCCESS,
      payload: response,
    };
  }

  static updateBackupEmailFailed(): Action {
    return {
      type: mfaActionType.UPDATE_BACKUP_EMAIL_FAILED,
    };
  }

  static verifyBackupEmail(mfa: MfaVerifyBackupEmailRequest): Action {
    return {
      type: mfaActionType.VERIFY_BACKUP_EMAIL,
      payload: mfa,
    };
  }

  static verifyBackupEmailSuccess(response: any): Action {
    return {
      type: mfaActionType.VERIFY_BACKUP_EMAIL_SUCCESS,
      payload: response,
    };
  }

  static verifyBackupEmailFailed(): Action {
    return {
      type: mfaActionType.VERIFY_BACKUP_EMAIL_FAILED,
    };
  }

  static sendEmailWithOTPCode(): Action {
    return {
      type: mfaActionType.SEND_EMAIL_WITH_OTP_CODE,
    };
  }

  static sendEmailWithOTPCodeSuccess(response: any): Action {
    return {
      type: mfaActionType.SEND_EMAIL_WITH_OTP_CODE_SUCCESS,
      payload: response,
    };
  }

  static sendEmailWithOTPCodeFailed(): Action {
    return {
      type: mfaActionType.SEND_EMAIL_WITH_OTP_CODE_FAILED,
    };
  }

  static updatePromote(): Action {
    return {
      type: mfaActionType.UPDATE_PROMOTE,
    };
  }

  static updatePromoteSuccess(response: any): Action {
    return {
      type: mfaActionType.UPDATE_PROMOTE_SUCCESS,
      payload: response,
    };
  }

  static updatePromoteFailed(): Action {
    return {
      type: mfaActionType.UPDATE_BACKUP_EMAIL_FAILED,
    };
  }

  static refreshBackupCodeSuccess(response: MfaBackupCodeResponse): Action {
    return {
      type: mfaActionType.REFRESH_BACKUP_CODE_SUCCESS,
      payload: response,
    };
  }

  static refreshBackupCodeFailed() {
    return {
      type: mfaActionType.REFRESH_BACKUP_CODE_FAILED,
    };
  }
}
