import { Injectable } from '@angular/core';
import { compose, Store } from '@ngrx/store';
import { createSelector } from 'reselect';
import { Observable, of as observableOf, throwError as observableThrowError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { AppState } from '../index';
import { MessageAction } from '../message/message.action';
import { MfaAction } from './mfa.action';
import { MfaApi } from './mfa.api';
import {
  MfaBackupCodeRequest,
  MfaBackupCodeResponse,
  MfaDisableRequest,
  MfaEnableRequest,
  MfaLoginRequest,
  MfaLoginWithOTPRequest,
  MfaSetupRequest,
  MfaState,
  MfaUpdateBackupEmailRequest,
  MfaVerifyBackupEmailRequest,
} from './mfa.model';

@Injectable()
export class MfaService {
  private _password: string | null;

  public constructor(
    private store: Store<AppState>,
    private api: MfaApi,
  ) {}

  public setup(payload: MfaSetupRequest) {
    this.store.dispatch(MfaAction.setup(payload));

    return this.api.setup(payload).pipe(
      map((response) => {
        this.store.dispatch(MfaAction.setupSuccess(response));
        return response;
      }),
      catchError((response) => {
        this.store.dispatch(MfaAction.setupFailed());
        return observableThrowError(response);
      }),
    );
  }

  public enable(payload: MfaEnableRequest) {
    this.store.dispatch(MfaAction.enable(payload));

    return this.api.enable(this.normalizeVerificationCode(payload)).pipe(
      map((response) => {
        this.store.dispatch(MfaAction.enableSuccess(response, payload.provider));
        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(MfaAction.enableFailed());
        return observableThrowError(response);
      }),
    );
  }

  public disable(payload: MfaDisableRequest) {
    this.store.dispatch(MfaAction.disable(payload));

    return this.api.disable(this.normalizeVerificationCode(payload)).pipe(
      map((response) => {
        this.store.dispatch(MfaAction.disableSuccess(response));
        return response;
      }),
      catchError((response) => {
        this.store.dispatch(MfaAction.disableFailed());
        return observableThrowError(response);
      }),
    );
  }

  public disableForUser(userId: string) {
    this.store.dispatch(MfaAction.disableForUser(userId));

    return this.api.disableForUser(userId).pipe(
      tap(() => {
        this.store.dispatch(MfaAction.disableForUserSuccess(userId));
      }),
      catchError((response) => {
        this.store.dispatch(MfaAction.disableForUserFailed());
        return observableThrowError(response);
      }),
    );
  }

  public login(data: MfaLoginRequest): Observable<any> {
    return this.api.login(this.normalizeVerificationCode(data), MfaAction.login(data)).pipe(
      map((response) => {
        this.store.dispatch(MfaAction.loginSuccess(response));
        return response;
      }),
      catchError((response) => {
        this.store.dispatch(MfaAction.loginFailed());
        return observableThrowError(response);
      }),
    );
  }

  public loginWithOTP(data: MfaLoginWithOTPRequest): Observable<any> {
    return this.api.loginWithOTP(this.normalizeVerificationCode(data), MfaAction.loginWithOTP(data)).pipe(
      map((response) => {
        this.store.dispatch(MfaAction.loginWithOTPSuccess(response));
        return response;
      }),
      catchError((response) => {
        this.store.dispatch(MfaAction.loginWithOTPFailed());
        return observableThrowError(response);
      }),
    );
  }

  public sendEmailWithOTPCode() {
    this.store.dispatch(MfaAction.sendEmailWithOTPCode());

    return this.api.sendEmailWithOTPCode().pipe(
      map((response) => {
        this.store.dispatch(MfaAction.sendEmailWithOTPCodeSuccess(response));
        this.store.dispatch(MessageAction.success('An email with a back-up code has been sent.'));
        return response;
      }),
      catchError((response) => {
        this.store.dispatch(MfaAction.sendEmailWithOTPCodeFailed());
        this.store.dispatch(MessageAction.error(response));
        return observableThrowError(response);
      }),
    );
  }

  public getBackupCode(data: MfaBackupCodeRequest) {
    this.store.dispatch(MfaAction.getBackupCode(data));

    return this.api.getBackupCode(data).pipe(
      map((response) => {
        this.store.dispatch(MfaAction.getBackupCodeSuccess(response));
        return response;
      }),
      catchError((response) => {
        this.store.dispatch(MfaAction.getBackupCodeFailed());
        return observableThrowError(response);
      }),
    );
  }

  public verifyBackupCode(data: MfaBackupCodeResponse) {
    this.store.dispatch(MfaAction.verifyBackupCode());

    return this.api.verifyBackupCode(data).pipe(
      map((response) => {
        this.store.dispatch(MfaAction.verifyBackupCodeSuccess(response));
        return response;
      }),
      catchError((response) => {
        this.store.dispatch(MfaAction.verifyBackupCodeFailed());
        return observableThrowError(response);
      }),
    );
  }

  public updateBackupEmail(payload: MfaUpdateBackupEmailRequest) {
    this.store.dispatch(MfaAction.updateBackupEmail(payload));

    return this.api.updateBackupEmail(payload).pipe(
      map((response) => {
        this.store.dispatch(MfaAction.updateBackupEmailSuccess(response));
        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(MfaAction.updateBackupEmailFailed());
        return observableThrowError(response);
      }),
    );
  }

  public verifyBackupEmail(payload: MfaVerifyBackupEmailRequest) {
    this.store.dispatch(MfaAction.verifyBackupEmail(payload));

    return this.api.verifyBackupEmail(this.normalizeVerificationCode(payload)).pipe(
      map((response) => {
        this.store.dispatch(MfaAction.verifyBackupEmailSuccess(payload));
        return response;
      }),
      catchError((response) => {
        this.store.dispatch(MfaAction.verifyBackupEmailFailed());
        return observableThrowError(response);
      }),
    );
  }

  public updatePromoteState() {
    this.store.dispatch(MfaAction.updatePromote());

    return this.api.updatePromoteState().pipe(
      map((response) => {
        this.store.dispatch(MfaAction.updatePromoteSuccess(response));
        return response;
      }),
      catchError((response) => {
        this.store.dispatch(MfaAction.updatePromoteFailed());
        return observableThrowError(response);
      }),
    );
  }

  public clearPassword() {
    this._password = null;
  }

  public get password(): string {
    return this._password;
  }

  public set password(password: string) {
    this._password = password;
  }

  public refreshBackupCode() {
    return this.api.refreshBackupCode().pipe(
      map((response) => {
        this.store.dispatch(MfaAction.refreshBackupCodeSuccess(response));
        return response;
      }),
      catchError((response) => {
        this.store.dispatch(MfaAction.refreshBackupCodeFailed());
        return observableThrowError(response);
      }),
    );
  }

  private normalizeVerificationCode(payload) {
    const key = payload.verification_code ? 'verification_code' : 'code';

    if (payload[key]) {
      payload = {
        ...payload,
        [key]: payload[key].replace(/\s/g, ''),
      };
    }

    return payload;
  }
}

export const mfaState = (state: AppState) => state.mfa;

export const isMfaLoggedIn = compose((state: MfaState) => state.mfa_logged_in, mfaState);

export const isMfaEnforced = compose((state: MfaState) => state.mfa_enforced, mfaState);

export const getMfaProvider = compose((state: MfaState) => state.mfa_provider, mfaState);

export const getMfaBackupEmail = compose((state: MfaState) => state.mfa_backup_email, mfaState);

export const getMfaBackupCode = compose((state: MfaState) => state.mfa_backup_code, mfaState);

export const isMfaEnabled = createSelector(getMfaProvider, (provider) => provider !== null);

export const isMfaRequired = createSelector(
  isMfaLoggedIn,
  getMfaProvider,
  (isMfaLoggedIn, mfaProvider) => mfaProvider !== null && isMfaLoggedIn === false,
);

export const isMfaAuthenticated = createSelector(
  isMfaLoggedIn,
  getMfaProvider,
  (isMfaLoggedIn, mfaProvider) => mfaProvider !== null && isMfaLoggedIn === true,
);
